import React from 'react';
import { useTranslation } from 'react-i18next';
import ESGAnimation from 'components/esgAnimation';
import videoPlan from 'assets/videos/plant.mp4';
import PlantAnimation from 'components/plant';
import slider_1 from 'assets/images/esg/ESG-1-1.webp';
import slider_2 from 'assets/images/esg/ESG-3-3.webp';
import slider_3 from 'assets/images/esg/ESG-2.jpg';
import Animated from 'components/generals/AnimatedComponent';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, EffectFade } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css';

export default function ESGPage() {
    const { t } = useTranslation();

    return (
        <>
            <main className="nv__esg__page">
                <section className="nv__esg__page__portrait">
                    <ESGAnimation />
                </section>
                <section className="nv__esg__page__container">
                    <div className="nv__esg__page__block">
                        <Animated animation={'animate__fadeInUp  animate__delay-1s'}>
                            <div className="nv__esg__page__block__content item__esg__1"></div>
                        </Animated>
                    </div>
                    <div className="nv__esg__page__block">
                        <Animated animation={'animate__fadeInUp  animate__delay-2s'}>
                            <div className="nv__esg__page__block__content item__esg__2"></div>
                        </Animated>
                    </div>
                    <div className="nv__esg__page__block">
                        <Animated animation={'animate__fadeInUp  animate__delay-3s'}>
                            <div className="nv__esg__page__block__content item__esg__3"></div>
                        </Animated>
                    </div>

                    <div className="nv__esg__page__block__slider">
                        <Swiper
                            autoplay={{
                                delay: 4000,
                                disableOnInteraction: false,
                            }}
                            effect={'fade'}
                            loop={true}
                            navigation={false}
                            pagination={{ clickable: true }}
                            speed={1500}
                            modules={[Autoplay, Navigation, Pagination, EffectFade]}
                        >
                            <SwiperSlide>
                                <div className="nv__esg__page__block__slider__image">
                                    <img src={slider_1} alt="ESG" />
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="nv__esg__page__block__slider__image">
                                    <img src={slider_2} alt="ESG" />
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="nv__esg__page__block__slider__image">
                                    <img src={slider_3} alt="ESG" />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>

                    <section className="nv__esg__page__section">
                        <section className="nv__esg__page__info">
                            <Animated animation={'animate__fadeInUp'}>
                                <h1>{t('esg.title')}</h1>
                            </Animated>
                            <Animated animation={'animate__fadeInUp'}>
                                <p>{t('esg.paragraph1')}</p>
                            </Animated>
                            <Animated animation={'animate__fadeInUp'}>
                                <p>{t('esg.paragraph2')}</p>
                            </Animated>
                            <Animated animation={'animate__fadeInUp'}>
                                <p>{t('esg.paragraph3')}</p>
                            </Animated>
                        </section>
                        {/* <Animated animation={'animate__fadeInRight'}>
                            <PlantAnimation />
                        </Animated> */}
                    </section>
                </section>
            </main>
        </>
    );
}
