import React, { useEffect, useState } from 'react';
import { useGetPostQuery } from 'reduxStore/services/post.services';
import { Skeleton } from 'antd';
import Animated from 'components/generals/AnimatedComponent';

export default function BlogRSSs() {
    const [post, setPost] = useState(50);
    const { data, isLoading } = useGetPostQuery({
        per_page: post,
        page: 1,
        sticky: false,
        offset: 0,
    });
    const [loading, isLoadingScroll] = useState(true);

    console.log('DATAPOST', data);

    if (isLoading) {
        setTimeout(() => {
            isLoadingScroll(false);
        }, 2000);
    }

    return (
        <>
            <main className="nv__section nv__blog">
                <div className="nv__blog__header"></div>
                <section className="nv__section__container">
                    <h1 className="nv__section__title text-center">BLOG</h1>

                    <section className="nv__blog__container">
                        <Skeleton className="mb-30" loading={loading} active></Skeleton>
                        <Skeleton className="mb-30" loading={loading} active></Skeleton>
                        <Skeleton className="mb-30" loading={loading} active></Skeleton>
                        <Skeleton className="mb-30" loading={loading} active></Skeleton>
                        <Skeleton className="mb-30" loading={loading} active></Skeleton>
                        <Skeleton className="mb-30" loading={loading} active></Skeleton>
                        <Skeleton className="mb-30" loading={loading} active></Skeleton>
                        <Skeleton className="mb-30" loading={loading} active></Skeleton>

                        {data?.map((item: any, index: number) => (
                            <>
                                {item?.meta.metabox_posts_link_linkedin === '' ? (
                                    <>
                                        <section key={index} className="nv__blog__item">
                                            <Animated animation={'animate__fadeInUp'}>
                                                <a href={`/post/${item?.slug}`}>
                                                    <div className="nv__blog__item__container">
                                                        <div className="nv__blog__item__image">
                                                            <img
                                                                src={item.full_image}
                                                                alt={item.title.rendered}
                                                            />
                                                        </div>

                                                        <div className="nv__blog__item__data">
                                                            <h4>{item.title.rendered}</h4>
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: `${item.excerpt.rendered}`,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </a>
                                            </Animated>
                                        </section>
                                    </>
                                ) : (
                                    <>
                                        <section key={index} className="nv__blog__item">
                                            <Animated animation={'animate__fadeInUp'}>
                                                <a
                                                    href={item?.meta.metabox_posts_link_linkedin}
                                                    target="_blank"
                                                >
                                                    <div className="nv__blog__item__container">
                                                        <div className="nv__blog__item__image">
                                                            <img
                                                                src={item.full_image}
                                                                alt={item.title.rendered}
                                                            />
                                                        </div>

                                                        <div className="nv__blog__item__data">
                                                            <h4>{item.title.rendered}</h4>
                                                            <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html: `${item.excerpt.rendered}`,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </a>
                                            </Animated>
                                        </section>
                                    </>
                                )}
                            </>
                        ))}
                    </section>
                </section>
            </main>
        </>
    );
}
